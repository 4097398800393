<template>
    <div>

        <!-- Модальное окно при заказе сертификата -->
        <div class="modal is-active" v-if="orderModalActive">
            <div class="modal-background" v-on:click="openOrderModal"></div>
            <div class="modal-card">
                <header class="modal-card-head"><p class="modal-card-title">{{ $t('Connect the security token') }}</p>
                    <figure class="image is-48x48">
                        <img src="@/assets/img/insert_usb.gif" alt="Image">
                    </figure>
                </header>
                <section class="modal-card-body">
                    <p>{{ $t('Please, make sure that the USB port of your computer is connected with only one security token') }}. {{ $t('Do not remove it before the end of operation') }}.</p>
                    <div style="height: 1em;"></div>
                </section>
                <footer class="modal-card-foot">
                    <a class="button is-primary" v-on:click="Next">{{ $t('Continue') }}</a>
                    <a class="button" v-on:click="openOrderModal">{{ $t('Cancel') }}</a>
                </footer>
            </div>
        </div>

        <!-- Модальное окно - Рекомендуемый ключевой носитель -->
        <div class="modal is-active" v-if="keyModalActive">
            <div class="modal-background" v-on:click="openKeyModal"></div>
            <div class="modal-card">
                <header class="modal-card-head"><p class="modal-card-title">{{ $t('Recommended security token') }}</p>
                    <figure class="image is-48x48">
                        <img src="@/assets/icons/usb.svg" alt="Image">
                    </figure>
                </header>
                <section class="modal-card-body">
                    <div class="has-text-centered">
                        <p>{{ $t('Select the country of residence of your company') }}:</p>
                        <div style="height: 1em;"></div>
                        <div class="columns is-centered has-text-centered">
                            <div class="column is-6">
                                <div class="select is-fullwidth">
                                    <select name="country" v-model="selectedCountryId">
                                        <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                                    </select>
                                </div>
                                <div style="height: 2em;"></div>
                            </div>
                        </div>
                        <div class="columns has-text-centered">
                            <div class="column" v-if="selectedCountryId != 3">
                                <figure class="image is-96x96 is-center"><img src="@/assets/img/part_aknur.png"></figure>
                                <a href="http://aknur.kz/etoken-dlya-samruk-kazyna/etoken-dlya-polzovateley-nerezidentov-isez-ao-fnb-samruk-kazyna.html?lang=ru" target="_blank">SafeNet eToken 5110</a>
                            </div>
                            <div class="column" v-if="selectedCountryId == 3">
                                <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin2.png"></figure>
                                <a href="https://iecp.ru/services/nositeli/token-dlya-samruk-kazyna" target="_blank">JaCarta PKI/ГОСТ</a>
                            </div>
                            <div class="column" v-if="selectedCountryId == 42">
                                <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin.png"></figure>
                                <a href="http://www.ealaddin.com.ua/7-novosti/78-autentifikatsiya-na-torgovykh-ploshchadkakh.html" target="_blank">SafeNet eToken 5110</a>
                            </div>
<!--                            <div class="column" v-if="selectedCountryId != 3">-->
<!--                                <figure class="image is-96x96 is-center"><img src="@/assets/img/part_iepir.png"></figure>-->
<!--                                <a href="https://iepirkumi.lv/ru/drugije_servisi/kazahstan" target="_blank">JaCarta JC 100 </a>-->
<!--                            </div>-->
                            <div class="column" v-if="selectedCountryId != 3">
                                <figure class="image is-96x96 is-center" style="border: 1px solid #d0d1d1;padding: 40px 5px 0px;border-radius: 10px;"><img src="@/assets/img/part_thales.jpg"></figure>
                                <a href="https://cpl.thalesgroup.com/access-management/authenticators/pki-usb-authentication/etoken-5110-usb-token" target="_blank">SafeNet eToken 5110</a>
                            </div>
                        </div>
                    </div>
                    <div style="height: 1em;"></div>
                </section>
                <footer class="modal-card-foot">
                    <a class="button is-primary" v-on:click="openKeyModal">{{ $t('Close') }}</a>
                </footer>
            </div>
        </div>

        <!-- Модальное окно - Установка программного обеспечения -->
        <div class="modal is-active" v-if="softwareModalActive">
            <div class="modal-background" v-on:click="openSoftwareModal"></div>
            <div class="modal-card">
                <header class="modal-card-head"><p class="modal-card-title">{{ $t('Software installation') }}</p>
                    <figure class="image is-48x48">
                        <img src="@/assets/icons/computer.svg" alt="Image">
                    </figure>
                </header>
                <section class="modal-card-body">
                    <p>1. {{ $t('Security token software') }}:</p>
                    <ul>
                        <li><b>JaCarta PKI/ГОСТ</b> - <a href="https://www.aladdin-rd.ru/support/downloads/jacarta_client_30/" target="_blank">{{ $t('JaCarta Unified Client') }}</a></li>
                        <li><b>{{ $t('SafeNet eToken 5110') }}</b> <span v-html="$t('contact 2')"></span></li>
                    </ul>
                    <div style="height: 0.2em;"></div>
                    <p>2. {{ $t('Software 1') }} <a v-bind:href="'/application/distr/SetupCryptoSocket_' + lang + '.zip?v=1.0.11.1224.2'">{{ $t('CryptoSocket') }}</a>{{ $t('Software 2') }}</p>
                    <div style="height: 0.5em;"></div>
<!--                    <p>3. <a href="/application/distr/InstallCA_rus.exe">{{ $t('PKI Root Certificate Installation Wizard') }}</a></p>-->
                    <div style="height: 1em;"></div>
                </section>
                <footer class="modal-card-foot">
                    <a class="button is-primary" v-on:click="openSoftwareModal">{{ $t('Close') }}</a>
                </footer>
            </div>
        </div>

        <!-- Заголовок -->
        <section class="section">
            <div class="container has-text-centered">
                <h1 class="title is-3 is-marginless">{{ $t('To purchase a digital signature certificate, you need') }}:</h1>
            </div>
            <div style="height: 3rem;"></div>

            <!-- Контент -->
            <div class="container">
                <div style="height:0.75rem;"></div>
                <div class="content">

                    <div class="columns is-centered">
                        <div class="column is-8 card is-bgr-silver">
                            <div style="height:1rem;"></div>
                            <div class="columns">
                                <div class="column is-1">
                                    <figure class="image is-48x48"><img src="@/assets/icons/usb.svg" style="height: 50px"></figure>
                                </div>
                                <div v-if="lang == 'ru' || lang == 'en'" class="column is-center div-text">
                                    1. {{ $t('Purchase a') }} <a v-on:click="openKeyModal">{{ $t('recommended security token') }}</a>.
                                </div>
                                <div v-else class="column is-center div-text">
                                    1. <a v-on:click="openKeyModal">{{ $t('recommended security token') }}</a> {{ $t('Purchase a') }}.
                                </div>
                            </div>
                            <div style="height:1rem;"></div>
                            <div class="columns">
                                <div class="column is-1">
                                    <figure class="image is-48x48"><img src="@/assets/icons/computer.svg" style="height: 50px"></figure>
                                </div>
                                <div v-if="lang == 'ru' || lang == 'en'" class="column is-center div-text">
                                    2. {{ $t('Download and install') }} <a v-on:click="openSoftwareModal">{{ $t('software') }}</a> {{ $t('to operate with your security token') }}.
                                </div>
                                <div v-else class="column is-center div-text">
                                    2. {{ $t('to operate with your security token') }} <a v-on:click="openSoftwareModal">{{ $t('software') }}</a> {{ $t('Download and install') }}.
                                </div>
                            </div>
                            <div style="height:1rem;"></div>
                            <div v-if="step !== 'button-no'">
                                <div class="columns">
                                    <div class="column is-1">
                                        <figure class="image is-48x48"><img src="@/assets/icons/scanner.svg" style="height: 50px"></figure>
                                    </div>
                                    <div class="column is-center div-text">
                                        3. {{ $t('Prepare electronic scanned copies of the following documents') }}:<br>
                                        <ul class="list">
                                            <li>{{ $t('Certificate of registration of an organization with the tax authority in your country, confirming the assignment of the identification number or VAT number (VATIN/TAXIN)') }};</li>
                                            <li>{{ $t('If your company does not have an official name in Russian or in English, then a notarized translation of the Certificate of registration of the company with the tax authority in Russian or in English is required') }};</li>
                                            <li>{{ $t('Identity document of the person purchasing the EDS certificate') }}.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div style="height:1rem;"></div>
                            </div>
                        </div>
                    </div>

                    <div style="height:3rem;"></div>
                    <div class="columns is-centered">
                        <div class="column is-8 card">
                            <div style="height:1rem;"></div>
                            <div class="card-content">
                                <div class="columns">
                                    <div class="column">
                                        <p class="title is-5 level-item">{{$t('choose_cert')}}</p>
                                        <p>
                                            <label class="checkbox">
                                                <input :disabled="disableSamruk" type="checkbox" v-model="valueSAMRUK">
                                                {{$t('Certificate for use on the electronic procurement portal «Samruk-Kazyna»')}}
                                            </label>
                                        </p>
                                        <p>
                                            <label class="checkbox">
                                                <input :disabled="disableNBRK" type="checkbox" v-model="valueNBRK">
                                                {{$t('Certificate for use on the procurements portal of the National Bank of the Republic of Kazakhstan')}}
                                            </label>
                                        </p>
                                        <p>
                                            <label class="checkbox">
                                                <input :disabled="disableMITWORK" type="checkbox" v-model="valueMITWORK">
                                                {{$t('Certificate for use on the Eurasian electronic portal')}}
                                            </label>
                                        </p>
                                        <p>
                                            <label class="checkbox">
                                                <input :disabled="disableDocumentolog" type="checkbox" v-model="valueDocumentolog">
                                                {{$t('Certificate for use on the Documentolog electronic document management system')}}
                                            </label>
                                        </p>
                                    </div>
                                </div>
                                <div class="columns is-centered" v-if="template">
                                    <div class="column">
                                        <p>{{ $t('Validity 1 year') }}</p>
                                        <p>{{ $t('Value') }}: <b>{{pickedCurrency}} {{currencyType}}</b> &ensp;
                                            (
                                            <label class="radio currency">
                                                <input class="currency" type="radio" v-bind:value="template.price_kzt" v-model="pickedCurrency"
                                                       @click="updatePrice(template.price_kzt, 'KZT')">
                                                KZT
                                            </label>
                                            <label class="radio currency">
                                                <input class="currency" type="radio" v-bind:value="template.price_usd" v-model="pickedCurrency"
                                                       @click="updatePrice(template.price_usd, 'USD')">
                                                USD
                                            </label>
                                            <label class="radio currency">
                                                <input class="currency" type="radio" v-bind:value="template.price_rur" v-model="pickedCurrency"
                                                       @click="updatePrice(template.price_rur, 'RUB')">
                                                RUB
                                            </label>
                                            <label class="radio currency">
                                                <input class="currency" type="radio" v-bind:value="template.price_eur" v-model="pickedCurrency"
                                                       @click="updatePrice(template.price_eur, 'EUR')">
                                                EUR
                                            </label>
                                            )
                                        </p>
                                    </div>
                                    <div class="column is-3 is-right">
                                        <input class="button is-success is-large is-mobile-small" type="button" style="margin-top: 5px;" v-on:click="openOrderModal" v-bind:value="$t('Apply')">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height:2rem;"></div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  name: 'GetCertificate',
  data () {
    return {
      orderModalActive: false,
      keyModalActive: false,
      softwareModalActive: false,
      currencies: null,
      pickedCurrency: null,
      templates: null,
      countries: null,
      selectedCountryId: null,
      valueSAMRUK: false,
      valueMITWORK: false,
      valueNBRK: false,
      valueDocumentolog: false,
      disableSamruk: false,
      disableNBRK: false,
      disableMITWORK: false,
      disableDocumentolog: false,
      currencyType: 'KZT'
    }
  },
  mounted() {
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    step () {
      return this.$route.params.step
    },
    template () {
      if (this.valueSAMRUK) {
        if (this.valueMITWORK && this.valueNBRK) {
          this.disableDocumentolog = true
          return this.findTemplate('SAMRUK_MITWORK_NBRK_1Y')
        }
        if (this.valueMITWORK && !this.valueNBRK) {
          this.disableDocumentolog = true
          return this.findTemplate('SAMRUK_MITWORK_1Y')
        }
        if (!this.valueMITWORK && this.valueNBRK) {
          this.disableDocumentolog = true
          return this.findTemplate('SAMRUK_NBRK_1Y')
        }
        if (this.valueDocumentolog) {
          this.disableMITWORK = true
          this.disableNBRK = true
          return this.findTemplate('DOCUMENTOLOG_SAMRUK_1Y')
        }
        this.disableMITWORK = false
        this.disableNBRK = false
        this.disableDocumentolog = false
        return this.findTemplate('SAMRUK_1Y')
      }
      if (this.valueMITWORK) {
        this.disableDocumentolog = true
        if (this.valueNBRK) return this.findTemplate('MITWORK_NBRK_1Y')
        return this.findTemplate('MITWORK_1Y')
      }
      if (this.valueNBRK) {
        this.disableDocumentolog = true
        return this.findTemplate('NBRK_1Y')
      }
      if (this.valueDocumentolog) {
        this.disableMITWORK = true
        this.disableNBRK = true
        return this.findTemplate('Documentolog')
      }
      this.disableMITWORK = false
      this.disableNBRK = false
      this.disableDocumentolog = false

      return null
    },
    selectedCountryShortName () {
      return this.currentCountry ? this.currentCountry.shortname : null
    }
  },
  watch: {
    template: {
      handler: function (val) {
        if (this.template) {
          if (this.currencyType == 'USD') {
            this.pickedCurrency = this.template.price_usd
          } else if (this.currencyType == 'EUR') {
            this.pickedCurrency = this.template.price_eur
          } else if (this.currencyType == 'RUB') {
            this.pickedCurrency = this.template.price_rur
          } else {
            this.pickedCurrency = this.template.price_kzt
          }
        }
      }
    },
    countries: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/countries/').then(response => {
            this.countries = response.data
            this.$http.get('/query/get-country/').then(response => {
              if (response.data) {
                console.log(response.data)
                const country = this.countries ? this.countries.find(item => item.name === response.data) : null
                this.selectedCountryId = country.id
              } else {
                this.selectedCountryId = '2'
              }
            })
          })
        }
      },
      immediate: true
    },
    templates: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/templates/').then(response => {
            this.templates = response.data
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    findTemplate (tN) {
      return this.templates ? this.templates.find(item => item.template_name === tN) : null
    },
    updatePrice (price, currency) {
      this.currencyType = currency
    },
    openOrderModal () {
      this.orderModalActive = !this.orderModalActive
    },
    openKeyModal () {
      this.keyModalActive = !this.keyModalActive
    },
    openSoftwareModal () {
      this.softwareModalActive = !this.softwareModalActive
    },
    Next () {
      let tempCurrencyId = 4
      if (this.currencyType == 'RUB') {
        tempCurrencyId = 6
      } else if (this.currencyType == 'EUR') {
        tempCurrencyId = 7
      } else if (this.currencyType == 'USD') {
        tempCurrencyId = 5
      } else {
        tempCurrencyId = 4
      }
      window.localStorage.setItem('getCertType', 'new')

      window.localStorage.setItem('order', '')
      window.localStorage.setItem('OrderNumber', '')
      window.localStorage.setItem('OrderPassword', '')
      window.localStorage.setItem('currency', this.currencyType)
      window.localStorage.setItem('price', this.pickedCurrency)
      window.localStorage.setItem('currencyId', tempCurrencyId)
      window.localStorage.setItem('template', this.template.template_name)
      window.localStorage.setItem('templateId', this.template.id)
      window.localStorage.setItem('templateDescription_ru', this.template.description)
      window.localStorage.setItem('templateDescription_en', this.template.description_en)
      window.localStorage.setItem('templateDescription_kk', this.template.description_kk)
      window.localStorage.setItem('templateData', this.template.template)
      if (this.lang === 'ru') {
        window.localStorage.setItem('templateName', this.template.description)
        window.localStorage.setItem('templateValidity', this.template.validity)
      } else if (this.lang === 'en') {
        window.localStorage.setItem('templateName', this.template.description_en)
        window.localStorage.setItem('templateValidity', this.template.validity_en)
      } else {
        window.localStorage.setItem('templateName', this.template.description_kk)
        window.localStorage.setItem('templateValidity', this.template.validity_kk)
      }

      this.$router.push({ name: 'order_cert', params: { step: 'FormStepOne' } })
      this.$store.commit('updateMyTemplate', this.template)
    }
  }
}
</script>

<style scoped lang="scss">
  h5 {
    text-align: center;
  }
  .right {
    margin-left: 30px;
  }
  .is-center {
    margin: auto;
  }
  .is-right {
    text-align: right;
  }
  .is-bgr-silver {
    background-color: #f7f7f7;
  }
  .is-large {
    font-size: 1.2rem;
  }
  .card-column-content {
    padding: 0 0.8rem 2rem 1rem;
  }
  ul {
    list-style-type: circle;
    padding: 5px 30px;
  }
  li {
    padding: 5px 0;
  }
  .div-text {
     padding-left: 40px;
  }
  .list {
    padding: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;
  }
</style>
